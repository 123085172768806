import ReactSwitch from "react-switch";

const Switch = ({ checked, onChange, disabled }) => {
  return (
    <label>
      <ReactSwitch
        onColor={disabled ? "#C3AE8F" : "#007A79"}
        offColor={disabled ? "#C3AE8F" : undefined}
        offHandleColor={disabled ? "#EDE2CD" : undefined}
        onHandleColor={disabled ? "#EDE2CD" : undefined}
        disabled={disabled}
        uncheckedIcon={false}
        checkedIcon={false}
        checked={checked}
        onChange={onChange}
      />
    </label>
  );
};

export default Switch;
