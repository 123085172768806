const PaperBottom = () =>
    <svg xmlns="http://www.w3.org/2000/svg" width="371" height="15" viewBox="0 111 371 15" fill="none" className="paper-bottom-svg">
        <g filter="url(#filter0_d_2311_2091)">
            <path d="M-9 -8H374V119H350.062V112.5L343.5 119H327.188L324.5 114.464L322 119H182.5H91L88.5 115.031L86.75 119H47L45.5 115.031L43.0625 119H24L18 112.5L14.9375 119H-9V-8Z" fill="#F9F5EC" />
        </g>
        <defs>
            <filter id="filter0_d_2311_2091" x="-19" y="-16" width="403" height="147" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.258333 0 0 0 0 0.178767 0 0 0 0 0.0925694 0 0 0 0.2 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2311_2091" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2311_2091" result="shape" />
            </filter>
        </defs>
    </svg>

export default PaperBottom;
