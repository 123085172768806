import React, { useState, useRef, useEffect } from "react";
import "./style.css";

const Map = ({ src, zoom, setZoom, rightPaneOpen, dmMapUrl }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [initialPosition, setInitialPosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);

  const handleWheel = (e) => {
    e.preventDefault();
    const scaleAmount = 0.1;
    const newZoom = e.deltaY > 0 ? zoom - scaleAmount : zoom + scaleAmount;
    setZoom(Math.max(0.1, newZoom));
  };

  const handleMouseDown = (e) => {
    if (e.button !== 0) return;
    setIsDragging(true);
    setDragStart({ x: e.clientX, y: e.clientY });
    setInitialPosition({ x: position.x, y: position.y });
    containerRef.current.style.cursor = "grabbing";
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;

    const scaleFactor = 1 / zoom;
    const dx = (e.clientX - dragStart.x) * scaleFactor;
    const dy = (e.clientY - dragStart.y) * scaleFactor;
    setPosition({ x: initialPosition.x + dx, y: initialPosition.y + dy });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    containerRef.current.style.cursor = "grab";
  };

  const handleMouseLeave = () => {
    if (isDragging) {
      setIsDragging(false);
      containerRef.current.style.cursor = "grab";
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener("wheel", handleWheel);
    container.addEventListener("mousemove", handleMouseMove);
    container.addEventListener("mouseup", handleMouseUp);
    container.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      container.removeEventListener("wheel", handleWheel);
      container.removeEventListener("mousemove", handleMouseMove);
      container.removeEventListener("mouseup", handleMouseUp);
      container.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [isDragging, zoom, dragStart, initialPosition, position]);

  const preventImageDrag = (e) => {
    e.preventDefault();
  };

  return (
    <div
      className={`mapContainer ${dmMapUrl && "shrinkMapContainer"}`}
      onMouseDown={handleMouseDown}
      ref={containerRef}
      style={{ cursor: isDragging ? "grabbing" : "grab", marginRight: 50 }}
    >
      <img
        src={src}
        alt="Map"
        onDragStart={preventImageDrag}
        style={{
          transform: `scale(${zoom}) translate(${position.x}px, ${position.y}px)`,
        }}
      />
    </div>
  );
};

export default Map;
