import { getFirstAvailableTier } from "../../utils";

const SectionLabel = ({ text, disabled, small, featureKey }) => {
  return (
    <div className="sectionLabelContainer">
      <label
        className={`${small ? "smallLabel" : "label"} ${
          disabled && "disabledLabel"
        }`}
      >
        {text}
      </label>
      {disabled && (
        <div
          className="patreonLabelContainer"
          onClick={() =>
            window.open(
              "https://www.patreon.com/arcanecollector/membership",
              "_blank"
            )
          }
        >
          <img
            src="./images/patreon-icon-disabled.png"
            alt="Patreon Logo"
            className="patreonSmallImg"
          />
          <label className="patreonLabel">
            {getFirstAvailableTier(featureKey)} Feature
          </label>
        </div>
      )}
    </div>
  );
};

export default SectionLabel;
