import React from "react";

import "./style.css";

const Slider = ({ min, max, step, ticks, value, onChange, disabled }) => {
  const datalistOptions = ticks.map((tick, i) => <span key={i}>{tick}</span>);
  const sliderStyle = {
    background: `linear-gradient(to right, ${
      disabled ? "#C3AE8F" : "var(--Primary-600, #007A79)"
    } ${((value - min) / (max - min)) * 100}%, ${
      disabled ? "#C3AE8F" : "var(--Neutral-300, #D9C9AD)"
    } ${((value - min) / (max - min)) * 100}%)`,
  };

  return (
    <div className="sliderContainer">
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        className={`slider ${disabled && "disabled"}`}
        onChange={(e) => onChange(Number(e.target.value))}
        value={value}
        style={sliderStyle}
        disabled={disabled}
      />
      <div className="sliderticks selectNone">{datalistOptions}</div>
    </div>
  );
};

export default Slider;
