const API_URL = process.env.REACT_APP_API_URL;

export const login = () => {
  window.location.href = `${API_URL}/auth/patreon`;
};

export const logout = () => {
  return fetch(`${API_URL}/logout`, {
    method: "POST",
    credentials: "include",
  })
    .then((response) => {
      if (response.ok) {
        window.location.reload();
      } else {
        console.error("Logout failed");
      }
    })
    .catch((error) => {
      console.error("Error during logout:", error);
    });
};

export const checkAuth = () => {
  return fetch(`${API_URL}/auth/check`, { credentials: "include" }).then(
    (response) => response.json()
  );
};

export const getPatreonTier = () => {
  return fetch(`${API_URL}/proxy/patreon-memberships`, {
    credentials: "include",
  }).then((response) => response.json());
};

export const generateMap = async (request) => {
  const response = await fetch(`${API_URL}/generate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    throw new Error("Map Generation Spell has Failed");
  }

  const result = await response.json();
  return result;
};

export const getMapUrl = async (mapName) => {
  try {
    const response = await fetch(`${API_URL}/download?filename=${mapName}`, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error("Map Generation Spell has Failed");
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    return url;
  } catch (error) {
    console.error("There was an error downloading the map:", error);
    return null;
  }
};

export const sendEmail = async (email, filenames) => {
  fetch(`${API_URL}/sendmap`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, filenames }),
  });
};
