export const GENERATOR = {
  ROAD: "road",
  TAVERN: "tavern",
  WILDERNESS: "wilderness",
  DUNGEON: "dungeon",
};

// TODO: ARCHIVE AND TOMB ARE THEMES FOR REAL
export const TYPE = {
  ARCHIVE: "archive",
  TOMB: "tomb",
  CAVE: "cavenat",
};

export const TYPE_LABEL = {
  [TYPE.ARCHIVE]: "Classic",
  [TYPE.TOMB]: "Tomb",
  [TYPE.CAVE]: "Cave",
};

// TODO: THIS WILL BE THEME NOT TYPE AFTER WE HAVE THEMES
export const MAP_SIZE_MAX = {
  [TYPE.ARCHIVE]: 16,
  [TYPE.TOMB]: 11,
  [TYPE.CAVE]: 12,
};

export const THEMES = {};

export const THEMES_LABEL = {};

export const AVAILABLE_THEMES_PER_TYPE = {
  [TYPE.ARCHIVE]: [],
  [TYPE.TOMB]: [],
  [TYPE.CAVE]: [],
};

export const AVAILABLE_TYPES_PER_GENERATOR = {
  [GENERATOR.DUNGEON]: [TYPE.ARCHIVE, TYPE.TOMB, TYPE.CAVE],
  [GENERATOR.ROAD]: [],
  [GENERATOR.TAVERN]: [],
  [GENERATOR.WILDERNESS]: [],
};

export const DIFFICULTY = {
  EASY: "easy",
  MEDIUM: "medium",
  HARD: "hard",
};

export const GRID_TYPE = {
  NONE: "none",
  THIN: "thin_dark",
  THICK: "thick_dark",
  DOTTED: "dotted_dark",
  HEX: "hex_gray",
};

export const TIME = {
  DAY: "day",
  NIGHT: "night",
};

export const SEASON = {
  WINTER: "winter",
  SPRING: "spring",
  FALL: "fall",
};

export const LAYOUT_OPTIONS = [
  "random",
  "cross",
  "clustered",
  "linear",
  "branching",
  "continuous",
  "dead_ends",
];

export const PATREON_TIERS = {
  FREE: "FREE",
  LIBRARIAN: "LIBRARIAN",
  COLLECTOR: "COLLECTOR",
  FOUNDER: "FOUNDER",
  CURATOR: "CURATOR",
  ARCHITECT: "ARCHITECT",
};

export const FEATURE_KEYS = {
  [GENERATOR.TAVERN]: {
    TIME_OF_DAY: "Tavern Time of Day",
    TYPE: "Tavern Type",
    SEASON: "Tavern Season",
  },
  [GENERATOR.ROAD]: {
    EVENT: "Road Event",
    TIME_OF_DAY: "Road Time of Day",
    TYPE: "Road Type",
    INCLUDE_TAVERN: "Road Include Tavern",
    SEASON: "Road Season",
  },
  [GENERATOR.WILDERNESS]: {
    TIME_OF_DAY: "Wilderness Time of Day",
    TYPE: "Wilderness Type",
    SEASON: "Wilderness Season",
  },
  [GENERATOR.DUNGEON]: {
    AVAILABLE_THEMES: "Dungeon # of Available Themes",
    VARIATIONS: "Dungeon Variations",
  },
  MAP_SIZE: "Map Size",
  DM_GUIDE: "DM Guide",
  ADVANCED_SETTINGS: "Advanced Settings",
  GRID_TYPE: "Grid Type",
  CUSTOM_NAME: "Custom Name",
  LAYOUT_OPTIONS: "Layout Options",
  ABILITY_TO_SAVE_HISTORY: "Ability to Save/History",
};

export const FEATURE_TIERS = {
  // Tavern Features
  [FEATURE_KEYS[GENERATOR.TAVERN].TIME_OF_DAY]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COLLECTOR]: true,
    [PATREON_TIERS.CURATOR]: true,
    [PATREON_TIERS.ARCHITECT]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },
  [FEATURE_KEYS[GENERATOR.TAVERN].TYPE]: {
    [PATREON_TIERS.FREE]: "Urban",
    [PATREON_TIERS.COLLECTOR]: "Urban",
    [PATREON_TIERS.CURATOR]: "Any",
    [PATREON_TIERS.ARCHITECT]: "Any",
    [PATREON_TIERS.FOUNDER]: "Any",
  },
  [FEATURE_KEYS[GENERATOR.TAVERN].SEASON]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COLLECTOR]: false,
    [PATREON_TIERS.CURATOR]: false,
    [PATREON_TIERS.ARCHITECT]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },

  // Road Features
  [FEATURE_KEYS[GENERATOR.ROAD].EVENT]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COLLECTOR]: true,
    [PATREON_TIERS.CURATOR]: true,
    [PATREON_TIERS.ARCHITECT]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },
  [FEATURE_KEYS[GENERATOR.ROAD].TIME_OF_DAY]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COLLECTOR]: true,
    [PATREON_TIERS.CURATOR]: true,
    [PATREON_TIERS.ARCHITECT]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },
  [FEATURE_KEYS[GENERATOR.ROAD].TYPE]: {
    [PATREON_TIERS.FREE]: "Forest",
    [PATREON_TIERS.COLLECTOR]: "Forest",
    [PATREON_TIERS.CURATOR]: "Any",
    [PATREON_TIERS.ARCHITECT]: "Any",
    [PATREON_TIERS.FOUNDER]: "Any",
  },
  [FEATURE_KEYS[GENERATOR.ROAD].INCLUDE_TAVERN]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COLLECTOR]: false,
    [PATREON_TIERS.CURATOR]: true,
    [PATREON_TIERS.ARCHITECT]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },
  [FEATURE_KEYS[GENERATOR.ROAD].SEASON]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COLLECTOR]: false,
    [PATREON_TIERS.CURATOR]: false,
    [PATREON_TIERS.ARCHITECT]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },

  // Wilderness Features
  [FEATURE_KEYS[GENERATOR.WILDERNESS].TIME_OF_DAY]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COLLECTOR]: true,
    [PATREON_TIERS.CURATOR]: true,
    [PATREON_TIERS.ARCHITECT]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },
  [FEATURE_KEYS[GENERATOR.WILDERNESS].TYPE]: {
    [PATREON_TIERS.FREE]: "Forest",
    [PATREON_TIERS.COLLECTOR]: "Forest",
    [PATREON_TIERS.CURATOR]: "Any",
    [PATREON_TIERS.ARCHITECT]: "Any",
    [PATREON_TIERS.FOUNDER]: "Any",
  },
  [FEATURE_KEYS[GENERATOR.WILDERNESS].SEASON]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COLLECTOR]: false,
    [PATREON_TIERS.CURATOR]: false,
    [PATREON_TIERS.ARCHITECT]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },

  // Dungeon Features
  [FEATURE_KEYS[GENERATOR.DUNGEON].AVAILABLE_THEMES]: {
    [PATREON_TIERS.FREE]: 3,
    [PATREON_TIERS.COLLECTOR]: 5,
    [PATREON_TIERS.CURATOR]: 8,
    [PATREON_TIERS.ARCHITECT]: "Max",
    [PATREON_TIERS.FOUNDER]: "Max",
  },
  [FEATURE_KEYS[GENERATOR.DUNGEON].VARIATIONS]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COLLECTOR]: false,
    [PATREON_TIERS.CURATOR]: false,
    [PATREON_TIERS.ARCHITECT]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },

  // General Features
  [FEATURE_KEYS.MAP_SIZE]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COLLECTOR]: false,
    [PATREON_TIERS.CURATOR]: true,
    [PATREON_TIERS.ARCHITECT]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },
  [FEATURE_KEYS.LAYOUT_OPTIONS]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COLLECTOR]: false,
    [PATREON_TIERS.CURATOR]: true,
    [PATREON_TIERS.ARCHITECT]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },
  [FEATURE_KEYS.DM_GUIDE]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COLLECTOR]: false,
    [PATREON_TIERS.CURATOR]: false,
    [PATREON_TIERS.ARCHITECT]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },
  [FEATURE_KEYS.ADVANCED_SETTINGS]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COLLECTOR]: false,
    [PATREON_TIERS.CURATOR]: true,
    [PATREON_TIERS.ARCHITECT]: true,
    [PATREON_TIERS.FOUNDER]: true,
  },
  [FEATURE_KEYS.ABILITY_TO_SAVE_HISTORY]: {
    [PATREON_TIERS.FREE]: false,
    [PATREON_TIERS.COLLECTOR]: 10,
    [PATREON_TIERS.CURATOR]: 20,
    [PATREON_TIERS.ARCHITECT]: 30,
    [PATREON_TIERS.FOUNDER]: 30,
  },
};
