import React from "react";

import "./style.css";
import { getFirstAvailableTier } from "../../utils";

const Dropdown = ({ options, value, onChange, disabled, featureKey }) => {
  return (
    <div>
      <select
        className="visualsDropdown"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {options.map((option, index) => (
          <option
            key={index}
            value={option}
            disabled={disabled}
            title={
              disabled ? `${getFirstAvailableTier(featureKey)} FEATURE` : ""
            }
          >
            {typeof option === "string"
              ? option
                  .replace(/_/g, " ")
                  .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                    letter.toUpperCase()
                  )
              : option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
