const Divider = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="320"
    height="2"
    viewBox="0 0 320 2"
    fill="none"
    style={{ marginTop: "20px" }}
  >
    <path d="M0 1L320 0.999972" stroke="#D9C9AD" />
  </svg>
);

export default Divider;
