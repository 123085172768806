import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { CSSTransition } from "react-transition-group";

import {
  AVAILABLE_TYPES_PER_GENERATOR,
  AVAILABLE_THEMES_PER_TYPE,
  GRID_TYPE,
  SEASON,
  TIME,
  GENERATOR,
  TYPE_LABEL,
  LAYOUT_OPTIONS,
  FEATURE_KEYS,
} from "../../constants";

import Slider from "../../components/Slider";
import Switch from "../../components/Switch";

import PaperBottom from "../../svgs/PaperBottom";
import PaperTop from "../../svgs/PaperTop";
import GeneratorButton from "../../components/GeneratorButton";
import Divider from "../../svgs/Divider";
import Dropdown from "../../components/VisualsDropdown";
import SquareButton from "../../components/SquareButton";
import { getFeatureSetting, getMapSizeMax } from "../../utils";
import SectionLabel from "../../components/SectionLabel";
import AdvancedSettings from "./AdvancedSettings";

const LeftPanel = ({
  generateMap,
  loading,
  isAuthenticated,
  handleLogin,
  userTier,
}) => {
  const initialState = {
    generator: GENERATOR.DUNGEON,
    name: "",
    visuals: {
      type: null,
      style: null,
      variation: null,
    },
    layout: LAYOUT_OPTIONS[0],
    gridType: null,
    time: null,
    season: null,
    roadEvent: false,
    includeTavern: false,
    mapSize: 4,
    quality: 70,
    dmGuide: false,
    advancedSettings: {
      show: false,
      partyLevel: 5,
      partySize: 4,
      difficulty: "medium",
      seed: {
        show: false,
        value: null,
      },
    },
  };
  const [panelState, setPanelState] = useState(initialState);
  const [types, setTypes] = useState(
    AVAILABLE_TYPES_PER_GENERATOR[panelState.generator]
  );
  const [typeThemes, setTypeThemes] = useState([]);

  const paneContainerRef = useRef(null);
  const visualsRef = useRef(null);
  const visualDropdownRef = useRef(null);
  const layoutRef = useRef(null);
  const timeRef = useRef(null);
  const seasonRef = useRef(null);
  const mapSizeRef = useRef(null);
  const roadEventRef = useRef(null);
  const includeTavernRef = useRef(null);
  const advancedSettingsSwitchRef = useRef(null);
  const dmGuideSwitchRef = useRef(null);
  const advancedSettingsRef = useRef(null);

  const showLayout = panelState.generator === GENERATOR.DUNGEON;
  const showTime = panelState.generator === GENERATOR.TAVERN;
  const showSeason = panelState.generator === GENERATOR.TAVERN;
  const showMapSize = panelState.generator !== GENERATOR.TAVERN;
  const showRoadEvent = panelState.generator === GENERATOR.ROAD;
  const showIncludeTavern = panelState.generator === GENERATOR.ROAD;
  const showDmGuide =
    panelState.generator === GENERATOR.DUNGEON ||
    panelState.generator === GENERATOR.TAVERN;
  const showAdvancedSettings = true;

  const layoutDisabled = !getFeatureSetting(
    FEATURE_KEYS.LAYOUT_OPTIONS,
    userTier
  );
  const mapSizeDisabled = !getFeatureSetting(FEATURE_KEYS.MAP_SIZE, userTier);
  const dmGuideDisabled = !getFeatureSetting(FEATURE_KEYS.DM_GUIDE, userTier);
  const timeOfDayDisabled = !getFeatureSetting(
    FEATURE_KEYS[panelState.generator].TIME_OF_DAY,
    userTier
  );
  const seasonDisabled = !getFeatureSetting(
    FEATURE_KEYS[panelState.generator].SEASON,
    userTier
  );
  const roadEventDisabled = !getFeatureSetting(
    FEATURE_KEYS[panelState.generator].EVENT,
    userTier
  );
  const includeTavernDisabled = !getFeatureSetting(
    FEATURE_KEYS[panelState.generator].INCLUDE_TAVERN,
    userTier
  );
  const advancedSettingsDisabled = !getFeatureSetting(
    FEATURE_KEYS.ADVANCED_SETTINGS,
    userTier
  );

  const mapSizeMin = panelState.generator === GENERATOR.WILDERNESS ? 1 : 2;
  const mapSizeMax = getMapSizeMax(panelState);

  useEffect(() => {
    setTypes(AVAILABLE_TYPES_PER_GENERATOR[panelState.generator]);
    setPanelState({
      ...initialState,
      generator: panelState.generator,
      mapSize: panelState.generator === GENERATOR.DUNGEON ? 4 : mapSizeMin, // If we're generating a dungeon, default to map size 5
    });
  }, [panelState.generator]);

  useEffect(() => {
    if (panelState.visuals.type) {
      setTypeThemes(AVAILABLE_THEMES_PER_TYPE[panelState.visuals.type]);
    }
    if (panelState.mapSize > mapSizeMax) {
      setPanelState({ ...panelState, mapSize: mapSizeMax });
    }
  }, [panelState.visuals.type]);

  const scrollToBottom = () => {
    if (paneContainerRef.current) {
      paneContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  const handleGeneratorChange = (type) => {
    setPanelState({ ...panelState, generator: type });
  };

  const handleNameChange = (text) => {
    setPanelState({ ...panelState, name: text });
  };

  const handleTypeSelect = (type) => {
    setPanelState({
      ...panelState,
      visuals: { ...panelState.visuals, type },
    });
  };

  const handleGridTypeChange = (type) => {
    setPanelState({ ...panelState, gridType: type });
  };

  const handleLayoutChange = (layout) => {
    setPanelState({ ...panelState, layout });
  };

  const handleTimeChange = (time) => {
    setPanelState({ ...panelState, time: time });
  };

  const handleSeasonChange = (season) => {
    setPanelState({ ...panelState, season: season });
  };

  const handleRoadEventChange = (value) => {
    setPanelState({ ...panelState, roadEvent: value });
  };

  const handleIncludeTavernChange = (value) => {
    setPanelState({ ...panelState, includeTavern: value });
  };

  const handleDmGuideChange = (value) => {
    setPanelState({ ...panelState, dmGuide: value });
  };

  const handleQualityChange = (value) => {
    setPanelState({ ...panelState, quality: Number(value) });
  };

  const handleMapSizeChange = (value) => {
    setPanelState({ ...panelState, mapSize: value });
  };

  const handleMapSizeBlur = () => {
    setPanelState({
      ...panelState,
      mapSize: Math.max(mapSizeMin, Math.min(mapSizeMax, panelState.mapSize)),
    });
  };

  const handleSeedShow = (show) => {
    scrollToBottom();
    setPanelState({
      ...panelState,
      advancedSettings: {
        ...panelState.advancedSettings,
        seed: {
          ...initialState.advancedSettings.seed,
          show,
          value: show ? initialState.advancedSettings.seed.value : null,
        },
      },
    });
  };

  const handleSeedChange = (value) => {
    setPanelState({ ...panelState, seed: { ...panelState.seed, value } });
  };

  const handleAdvancedSettingsShowChange = (show) => {
    scrollToBottom();
    setPanelState({
      ...panelState,
      advancedSettings: {
        ...panelState.advancedSettings,
        show,
      },
    });
  };

  const handlePartyLevelChange = (value) => {
    setPanelState({
      ...panelState,
      advancedSettings: { ...panelState.advancedSettings, partyLevel: value },
    });
  };

  const handlePartyLevelBlur = () => {
    setPanelState({
      ...panelState,
      advancedSettings: {
        ...panelState.advancedSettings,
        partyLevel: Math.max(
          1,
          Math.min(20, panelState.advancedSettings.partyLevel)
        ),
      },
    });
  };

  const handlePartySizeChange = (value) => {
    setPanelState({
      ...panelState,
      advancedSettings: { ...panelState.advancedSettings, partySize: value },
    });
  };

  const handleDifficultyChange = (value) => {
    setPanelState({
      ...panelState,
      advancedSettings: { ...panelState.advancedSettings, difficulty: value },
    });
  };

  return (
    <div className="side-panel">
      <div className="header">
        <img src="images/ph_infinity-bold.svg" alt="Infinity" />
        <div className="titleContainer">
          <span className="title">Mythical Maps</span>
          <span className="subTitle">INFINITY</span>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="generatorSection">
          <GeneratorButton
            text={GENERATOR.DUNGEON}
            handleClick={handleGeneratorChange}
            active={panelState.generator === GENERATOR.DUNGEON}
          />
          <GeneratorButton
            text={GENERATOR.TAVERN}
            handleClick={handleGeneratorChange}
            active={panelState.generator === GENERATOR.TAVERN}
          />
          <GeneratorButton
            text={GENERATOR.WILDERNESS}
            handleClick={handleGeneratorChange}
            active={panelState.generator === GENERATOR.WILDERNESS}
          />
          <GeneratorButton
            text={GENERATOR.ROAD}
            handleClick={handleGeneratorChange}
            active={panelState.generator === GENERATOR.ROAD}
          />
        </div>
        <PaperBottom />
      </div>
      <div className="mainContainer" ref={paneContainerRef}>
        {!isAuthenticated && (
          <div
            style={{ width: "90%", marginTop: "1em", marginBottom: ".5em" }}
            onClick={handleLogin}
          >
            <div className="unlockButton">Unlock Features via Patreon!</div>
          </div>
        )}
        <div className="sectionContainer" style={{ marginTop: 10 }}>
          <div className="nameContainer">
            <label className="label">Name</label>
            <input
              type="text"
              className="textField"
              placeholder="e.g. The Boar's Head"
              value={panelState.name}
              onChange={(e) => handleNameChange(e.target.value)}
            />
          </div>
        </div>
        <CSSTransition
          in={types.length > 0}
          nodeRef={visualsRef}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <div
            className="sectionContainer"
            // style={{ marginTop: "30px" }}
            ref={visualsRef}
          >
            {/* <div className="visualsContainer"> */}
            {/* <label className="label">Visuals</label> */}
            {/* <div className="dropdownSelectionContainer"> */}
            <label className="label">Type</label>
            <div className="typeButtonContainer">
              {types.map((type, i) => (
                <div
                  className={`${
                    panelState.visuals.type === type
                      ? "typeButtonSelected"
                      : "typeButton"
                  } selectNone`}
                  onClick={() => handleTypeSelect(type)}
                  key={i}
                >
                  <label
                    className={`${
                      panelState.visuals.type === type
                        ? "typeLabelSelected"
                        : "typeLabel"
                    } selectNone`}
                    key={i}
                  >
                    {TYPE_LABEL[type]}
                  </label>
                </div>
              ))}
            </div>
            {/* </div> */}
            {/* <CSSTransition
                in={typeStyles.length > 0}
                nodeRef={visualDropdownRef}
                timeout={200}
                classNames="fade"
                unmountOnExit
              >
                <div
                  className="dropdownSelectionContainer"
                  style={{ marginLeft: "20px" }}
                  ref={visualDropdownRef}
                >
                  <div>
                    <LShape />
                    <label className="smallLabel">
                      Style of{" "}
                      {panelState.visuals.type &&
                        panelState.visuals.type.charAt(0).toUpperCase() +
                          panelState.visuals.type.slice(1)}
                    </label>
                  </div>
                  <VisualsDropdown options={typeStyles} />
                </div>
              </CSSTransition> */}
            {/* <div className='dropdownSelectionContainer' style={{ marginLeft: '20px' }}>
                            <div style={{ marginLeft: '20px' }}>
                                <LShape />
                                <label className='smallLabel'>{'Temple'} Variations</label>
                            </div>
                            <VisualsDropdown />
                        </div> */}
            <Divider />
            {/* </div> */}
          </div>
        </CSSTransition>
        <div className="sectionContainer">
          <label className="label">Grid Type</label>
          <div className="gridTypeButtonContainer">
            <SquareButton
              text={GRID_TYPE.NONE}
              handleClick={handleGridTypeChange}
              active={panelState.gridType === GRID_TYPE.NONE}
            />
            <SquareButton
              text={GRID_TYPE.THIN}
              handleClick={handleGridTypeChange}
              active={panelState.gridType === GRID_TYPE.THIN}
            />
            <SquareButton
              text={GRID_TYPE.THICK}
              handleClick={handleGridTypeChange}
              active={panelState.gridType === GRID_TYPE.THICK}
            />
            <SquareButton
              text={GRID_TYPE.DOTTED}
              handleClick={handleGridTypeChange}
              active={panelState.gridType === GRID_TYPE.DOTTED}
            />
            <SquareButton
              text={GRID_TYPE.HEX}
              handleClick={handleGridTypeChange}
              active={panelState.gridType === GRID_TYPE.HEX}
            />
          </div>
        </div>
        <CSSTransition
          in={showLayout}
          nodeRef={layoutRef}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <div
            className={`sectionContainer ${
              layoutDisabled && "disabledSection"
            }`}
            ref={layoutRef}
          >
            <SectionLabel
              text="Layout Options"
              disabled={layoutDisabled}
              featureKey={FEATURE_KEYS.LAYOUT_OPTIONS}
            />
            <Dropdown
              options={LAYOUT_OPTIONS}
              value={panelState.layout}
              onChange={handleLayoutChange}
              disabled={layoutDisabled}
              featureKey={FEATURE_KEYS.LAYOUT_OPTIONS}
            />
          </div>
        </CSSTransition>
        <CSSTransition
          in={showMapSize}
          nodeRef={mapSizeRef}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <div
            className={`sectionContainer ${
              mapSizeDisabled && "disabledSection"
            }`}
            ref={mapSizeRef}
          >
            <SectionLabel
              text="Map Size"
              disabled={mapSizeDisabled}
              featureKey={FEATURE_KEYS.MAP_SIZE}
            />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Slider
                min={mapSizeMin}
                max={mapSizeMax}
                step={1}
                ticks={[mapSizeMin, mapSizeMax]}
                value={panelState.mapSize}
                onChange={handleMapSizeChange}
                disabled={mapSizeDisabled}
              />
              <input
                type="number"
                max={mapSizeMax}
                min={mapSizeMin}
                value={panelState.mapSize}
                onChange={(e) => handleMapSizeChange(Number(e.target.value))}
                onBlur={handleMapSizeBlur}
                style={{ height: 20, width: 20, marginLeft: 20, marginTop: 5 }}
                className="no-arrows"
                disabled={mapSizeDisabled}
              />
            </div>
          </div>
        </CSSTransition>
        <CSSTransition
          in={showTime}
          nodeRef={timeRef}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <div
            className={`sectionContainer ${
              timeOfDayDisabled && "disabledSection"
            }`}
            ref={timeRef}
          >
            <SectionLabel
              text="Time"
              disabled={timeOfDayDisabled}
              featureKey={FEATURE_KEYS[panelState.generator].TIME_OF_DAY}
            />
            <div className="gridTypeButtonContainer">
              <SquareButton
                text={TIME.DAY}
                handleClick={handleTimeChange}
                active={panelState.time === TIME.DAY}
                disabled={timeOfDayDisabled}
                defeaultSelection
              />
              <SquareButton
                text={TIME.NIGHT}
                handleClick={handleTimeChange}
                active={panelState.time === TIME.NIGHT}
                disabled={timeOfDayDisabled}
              />
            </div>
          </div>
        </CSSTransition>
        <CSSTransition
          in={showSeason}
          nodeRef={seasonRef}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <div
            className={`sectionContainer ${
              seasonDisabled && "disabledSection"
            }`}
            ref={seasonRef}
          >
            <SectionLabel
              text="Season"
              disabled={seasonDisabled}
              featureKey={FEATURE_KEYS[panelState.generator].SEASON}
            />
            <div className="gridTypeButtonContainer">
              <SquareButton
                text={SEASON.SPRING}
                handleClick={handleSeasonChange}
                active={panelState.season === SEASON.SPRING}
                disabled={seasonDisabled}
                defeaultSelection
              />
              <SquareButton
                text={SEASON.FALL}
                handleClick={handleSeasonChange}
                active={panelState.season === SEASON.FALL}
                disabled={seasonDisabled}
              />
              <SquareButton
                text={SEASON.WINTER}
                handleClick={handleSeasonChange}
                active={panelState.season === SEASON.WINTER}
                disabled={seasonDisabled}
              />
            </div>
          </div>
        </CSSTransition>
        <CSSTransition
          in={showRoadEvent}
          nodeRef={roadEventRef}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <div
            className={`sectionContainer ${
              roadEventDisabled && "disabledSection"
            }`}
            ref={roadEventRef}
          >
            <SectionLabel
              text="Road Event"
              disabled={roadEventDisabled}
              featureKey={FEATURE_KEYS[panelState.generator].EVENT}
            />
            <div style={{ marginTop: 5 }}>
              <Switch
                checked={panelState.roadEvent}
                onChange={handleRoadEventChange}
                disabled={roadEventDisabled}
              />
            </div>
          </div>
        </CSSTransition>
        <CSSTransition
          in={showIncludeTavern}
          nodeRef={includeTavernRef}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <div
            className={`sectionContainer ${
              includeTavernDisabled && "disabledSection"
            }`}
            ref={includeTavernRef}
          >
            <SectionLabel
              text="Include Tavern"
              disabled={includeTavernDisabled}
              featureKey={FEATURE_KEYS[panelState.generator].INCLUDE_TAVERN}
            />
            <div style={{ marginTop: 5 }}>
              <Switch
                checked={panelState.includeTavern}
                onChange={handleIncludeTavernChange}
                disabled={includeTavernDisabled}
              />
            </div>
          </div>
        </CSSTransition>
        {/* <div className="sectionContainer">
          <label className="label">Quality (DPI)</label>
          <VisualsDropdown
            options={[70, 150, 300]}
            onChange={handleQualityChange}
          />
        </div> */}
        <CSSTransition
          in={showDmGuide}
          nodeRef={dmGuideSwitchRef}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <div
            className={`sectionContainer ${
              dmGuideDisabled && "disabledSection"
            }`}
            ref={dmGuideSwitchRef}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SectionLabel
                text="DM Guide"
                disabled={dmGuideDisabled}
                featureKey={FEATURE_KEYS.DM_GUIDE}
              />
              <label
                className="smallLabel"
                style={{ marginTop: 5, marginBottom: 10 }}
              >
                Generates a PDF file with descriptions
              </label>
              <Switch
                checked={panelState.dmGuide}
                onChange={handleDmGuideChange}
                disabled={dmGuideDisabled}
              />
            </div>
          </div>
        </CSSTransition>

        {/* Advanced Settings Switch */}
        <CSSTransition
          in={showAdvancedSettings}
          nodeRef={advancedSettingsSwitchRef}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <div className={`sectionContainer`} ref={advancedSettingsSwitchRef}>
            <label className="label">Advanced Settings</label>
            <div style={{ marginTop: 5 }}>
              <Switch
                checked={panelState.advancedSettings.show}
                onChange={handleAdvancedSettingsShowChange}
              />
            </div>
          </div>
        </CSSTransition>
        <CSSTransition
          in={panelState.advancedSettings.show}
          nodeRef={advancedSettingsRef}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <AdvancedSettings
            advancedSettingsRef={advancedSettingsRef}
            disabled={advancedSettingsDisabled}
            panelState={panelState}
            handlePartyLevelChange={handlePartyLevelChange}
            handlePartyLevelBlur={handlePartyLevelBlur}
            handlePartySizeChange={handlePartySizeChange}
            handleDifficultyChange={handleDifficultyChange}
            handleSeedShow={handleSeedShow}
            handleSeedChange={handleSeedChange}
          />
        </CSSTransition>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 5,
        }}
      >
        <PaperTop />
        <div className="bottomSection">
          <div className="advancedSectionContainer" style={{ width: "90%" }}>
            <div
              className="unlockButton selectNone"
              onClick={() => generateMap(panelState)}
              style={{
                opacity: loading ? 0.5 : 1,
                pointerEvents: loading ? "none" : "auto",
              }}
            >
              Generate Map
            </div>
          </div>
        </div>
        <div className="divider" />
        <div
          className="footer"
          onClick={() =>
            window.open("https://www.patreon.com/ArcaneCollector", "_blank")
          }
        >
          <span className="footerLabel">Support us on </span>{" "}
          <img src="images/patreon-dark.png" alt="Patreon logo" />{" "}
          <img src="images/patreon-label.png" alt="Patreon label" />
        </div>
      </div>
    </div>
  );
};

export default LeftPanel;
