import React from "react";
import "./style.css";
import SquareIcons from "../../svgs/SquareIcons";

const SquareButton = ({
  text,
  handleClick,
  active,
  disabled,
  defeaultSelection,
}) => {
  const formattedText = text.split("_")[0];

  let buttonClass = "squareButton";
  let iconColor = "#54422E";

  if (disabled) {
    buttonClass = "squareButtonDisabled";
    iconColor = "#A78F6E";
    if (defeaultSelection) {
      buttonClass = "squareButtonDisabledDefault";
      iconColor = "#FCFAF6";
    }
  } else if (active) {
    buttonClass = "squareButtonActive";
    iconColor = "#FCFAF6";
  }

  return (
    <div
      className="squareButtonContainer"
      onClick={!disabled ? () => handleClick(text) : null}
    >
      <div className={buttonClass}>
        <SquareIcons type={text} color={iconColor} />
      </div>
      <label className="smallLabel">
        {formattedText.charAt(0).toUpperCase() + formattedText.slice(1)}
      </label>
    </div>
  );
};

export default SquareButton;
