const PaperTop = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="371"
    height="15"
    viewBox="0 0 371 15"
    fill="none"
    className="paper-top-svg"
  >
    <g filter="url(#filter0_d_2275_105031)">
      <path
        d="M376 175L-7 175L-6.99999 7.99997L16.9375 7.99997L16.9375 14.5L23.5 7.99997L39.8125 7.99997L42.5 12.5357L45 7.99997L184.5 7.99998L276 7.99999L278.5 11.9687L280.25 7.99999L320 8L321.5 11.9687L323.938 8L343 8L349 14.5L352.063 8L376 8L376 175Z"
        fill="#F9F5EC"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2275_105031"
        x="-17"
        y="0"
        width="403"
        height="187"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.258333 0 0 0 0 0.178767 0 0 0 0 0.0925694 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2275_105031"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2275_105031"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default PaperTop;
