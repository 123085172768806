import React from "react";
import "./style.css";
import TypeIcon from "../../svgs/TypeIcons";

const GeneratorButton = ({ text, handleClick, active }) => {
  return (
    <div
      className={
        (active ? "generatorButtonSelected" : "generatorButton") + " selectNone"
      }
      onClick={() => handleClick(text)}
    >
      <TypeIcon type={text} color={active ? "#F9F5EC" : "#54422E"} />
      <span
        className={
          (active ? "generatorLabelSelected" : "generatorLabel") + " selectNone"
        }
      >
        {text.charAt(0).toUpperCase() + text.slice(1)}
      </span>
    </div>
  );
};

export default GeneratorButton;
