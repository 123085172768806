import React from "react";
import "./style.css";

const RightBar = ({
  infoActive,
  emailActive,
  showEmail,
  handleInfoClick,
  handleEmailClick,
}) => {
  return (
    <div className="rightBarContainer">
      <img
        src={`images/right-info-${infoActive ? "active" : "inactive"}.svg`}
        alt="Info button"
        className="infoButton"
        onClick={handleInfoClick}
      />
      {showEmail && (
        <img
          src={`images/email-${emailActive ? "active" : "inactive"}.svg`}
          alt="Email"
          style={{
            width: 30,
            height: 30,
            cursor: "pointer",
            border: `1px solid ${emailActive ? "#007A79" : "#D9C9AD"}`,
            borderRadius: "10%",
          }}
          onClick={handleEmailClick}
        />
      )}
    </div>
  );
};

export default RightBar;
