import React, { useEffect, useRef, useState } from "react";
import "./style.css";

import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";

import PaperBottom from "../../svgs/PaperBottom";
import { sendEmail } from "../../api";

const AccordionItem = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={
      <div className="headerContent">
        <span className="headerText">{header}</span>
        <img
          className="chevron"
          src="images/chevron-down.svg"
          alt="Chevron Down"
        />
      </div>
    }
    className="item"
    buttonProps={{
      className: ({ isEnter }) =>
        `${"itemBtn"} ${isEnter && "itemBtnExpanded"}`,
    }}
    contentProps={{ className: "itemContent" }}
    panelProps={{ className: "itemPanel" }}
  />
);

const DMInfoPane = ({ mapData, emailActive }) => {
  const paneContainerRef = useRef(null);

  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const mapInfo = mapData?.map_info;

  const scrollToBottom = () => {
    if (paneContainerRef.current) {
      paneContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  useEffect(() => {
    if (!mapInfo) {
      setEmailSent(false);
      setEmail("");
    }
  }, [mapInfo]);

  const validateEmail = () => {
    var re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSendEmail = () => {
    const filenames = Object.values(mapData.filenames).filter(
      (value) => value !== ""
    );
    sendEmail(email, filenames);
    setEmailSent(true);
    setEmail("");
  };

  const handleEmailChange = (text) => {
    setEmail(text);
  };

  return (
    <div className={`dmInfoPaneContainer`}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="dmInfoHeaderContainer">
          <label className="infoHeader">
            {emailActive ? "Email Map" : "DM Information"}
          </label>
        </div>
        <PaperBottom />
      </div>
      <div className="dmInfoContentContainer" ref={paneContainerRef}>
        {!emailActive && mapInfo && mapInfo.length > 0
          ? mapInfo.map((info, index) => (
              <Accordion
                transition
                transitionTimeout={250}
                style={{ width: "100%" }}
                key={index}
                onClick={scrollToBottom}
              >
                <AccordionItem header={info.title} initialEntered={index === 0}>
                  {info.sections.map((section, sectionIndex) => (
                    <div className="accordionSection" key={sectionIndex}>
                      <label className="itemLabel">{section.title}</label>
                      {typeof section.body === "string" ? (
                        <div>{section.body}</div>
                      ) : (
                        section.body.map((line, i) => <div key={i}>{line}</div>)
                      )}
                    </div>
                  ))}
                </AccordionItem>
              </Accordion>
            ))
          : !emailActive && (
              <div className="emptyInfoContainer">
                <div className="emptyInfo">
                  <img src="images/right-info-large.svg" alt="Info icon" />
                  <label className="infoLabel">
                    Create a map to Generate DM Notes!
                  </label>
                </div>
              </div>
            )}
        {emailActive && mapInfo && !emailSent && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "20px",
              gap: "20px",
            }}
          >
            <input
              type="email"
              className="textField"
              placeholder="Enter your email"
              style={{ width: "80%" }}
              value={email}
              onChange={(e) => handleEmailChange(e.target.value)}
            />
            <div
              className="itemLabel"
              style={{ width: "80%", fontSize: "12px" }}
            >
              Enter your email to get the map and PDFs! We'll also keep you in
              the loop with future updates and send occasional bonus content
            </div>
            <div
              className="unlockButton selectNone"
              style={{
                width: "80%",
                height: "50px",
                opacity: validateEmail() ? 1 : 0.5,
                pointerEvents: validateEmail() ? "auto" : "none",
              }}
              onClick={handleSendEmail}
            >
              Send
            </div>
          </div>
        )}
        {emailActive && !mapInfo && (
          <div className="emptyInfoContainer">
            <div className="emptyInfo">
              <img
                src="images/email-light.svg"
                alt="Email icon"
                style={{ height: "200px", width: "200px" }}
              />

              <label className="infoLabel">
                Create a map to Send an Email!
              </label>
            </div>
          </div>
        )}
        {emailSent && emailActive && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              className="itemLabel"
              style={{ marginTop: "40px", fontSize: "24px" }}
            >
              Map Sent!
            </div>
            <div className="infoLabel">
              Thanks for using Mythical Maps Infinity!
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DMInfoPane;
