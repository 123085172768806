import { useRef } from "react";
import "./style.css";
import { CSSTransition } from "react-transition-group";
import Slider from "../../../components/Slider";
import Switch from "../../../components/Switch";
import Divider from "../../../svgs/Divider";
import { FEATURE_KEYS, GENERATOR } from "../../../constants";
import SectionLabel from "../../../components/SectionLabel";

const AdvancedSettings = ({
  advancedSettingsRef,
  disabled,
  panelState,
  handlePartyLevelChange,
  handlePartyLevelBlur,
  handlePartySizeChange,
  handleDifficultyChange,
  handleSeedShow,
  handleSeedChange,
}) => {
  const customSeedRef = useRef(null);
  const partyLevelRef = useRef(null);
  const partySizeRef = useRef(null);
  const difficultyRef = useRef(null);

  const showPartyLevel = panelState.generator === GENERATOR.DUNGEON;
  const showPartySize = panelState.generator === GENERATOR.DUNGEON;
  const showDifficulty = panelState.generator === GENERATOR.DUNGEON;

  return (
    <div ref={advancedSettingsRef} className="sectionContainer">
      <Divider />

      <SectionLabel
        text="Advanced Settings"
        disabled={disabled}
        small
        featureKey={FEATURE_KEYS.ADVANCED_SETTINGS}
      />
      <div className="advancedSectionContainer">
        <label
          className={`label 
            ${disabled && "disabledColorText"}`}
        >
          Custom Seed
        </label>
        <div style={{ marginTop: 5 }}>
          <Switch
            checked={panelState.advancedSettings.seed.show}
            onChange={handleSeedShow}
            disabled={disabled}
          />
        </div>
        <CSSTransition
          in={panelState.advancedSettings.seed.show}
          nodeRef={customSeedRef}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <input
            type="number"
            className="textField"
            style={{ width: "80%" }}
            placeholder="e.g. 1428317263"
            value={panelState.advancedSettings.seed.value || ""}
            onChange={(e) => handleSeedChange(e.target.value)}
            ref={customSeedRef}
          />
        </CSSTransition>
      </div>
      <CSSTransition
        in={showPartyLevel}
        nodeRef={partyLevelRef}
        timeout={200}
        classNames="fade"
        unmountOnExit
      >
        <div className="advancedSectionContainer" ref={partyLevelRef}>
          <label
            className={`label 
            ${disabled && "disabledColorText"}`}
          >
            Party Level
          </label>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Slider
              min={1}
              max={20}
              step={1}
              ticks={[1, 20]}
              value={panelState.advancedSettings.partyLevel}
              onChange={handlePartyLevelChange}
              disabled={disabled}
            />
            <input
              type="number"
              min={1}
              max={20}
              value={panelState.advancedSettings.partyLevel}
              onChange={(e) => handlePartyLevelChange(Number(e.target.value))}
              onBlur={handlePartyLevelBlur}
              disabled={disabled}
              style={{
                height: 20,
                width: 20,
                marginLeft: 20,
                marginTop: 5,
              }}
              className="no-arrows"
            />
          </div>
        </div>
      </CSSTransition>
      <CSSTransition
        in={showPartySize}
        nodeRef={partySizeRef}
        timeout={200}
        classNames="fade"
        unmountOnExit
      >
        <div className="advancedSectionContainer" ref={partySizeRef}>
          <label
            className={`label 
            ${disabled && "disabledColorText"}`}
          >
            Party Size
          </label>
          <Slider
            min={1}
            max={5}
            step={1}
            ticks={[1, 2, 3, 4, 5]}
            value={panelState.advancedSettings.partySize}
            onChange={handlePartySizeChange}
            disabled={disabled}
          />
        </div>
      </CSSTransition>
      <CSSTransition
        in={showDifficulty}
        nodeRef={difficultyRef}
        timeout={200}
        classNames="fade"
        unmountOnExit
      >
        <div className="advancedSectionContainer" ref={difficultyRef}>
          <label
            className={`label 
            ${disabled && "disabledColorText"}`}
          >
            Difficulty
          </label>
          <div className="difficultyButtonContainer">
            <div
              className={`wideButton ${
                panelState.advancedSettings.difficulty === "easy" &&
                "wideButtonActive"
              } ${
                disabled
                  ? panelState.advancedSettings.difficulty === "easy"
                    ? "disabledDefaultWideButton"
                    : "disabledWideButton"
                  : ""
              } selectNone`}
              onClick={() => !disabled && handleDifficultyChange("easy")}
            >
              Easy
            </div>
            <div
              className={`wideButton ${
                panelState.advancedSettings.difficulty === "medium" &&
                "wideButtonActive"
              } ${
                disabled
                  ? panelState.advancedSettings.difficulty === "medium"
                    ? "disabledDefaultWideButton"
                    : "disabledWideButton"
                  : ""
              } selectNone`}
              onClick={() => !disabled && handleDifficultyChange("medium")}
            >
              Medium
            </div>
            <div
              className={`wideButton ${
                panelState.advancedSettings.difficulty === "hard" &&
                "wideButtonActive"
              } ${
                disabled
                  ? panelState.advancedSettings.difficulty === "hard"
                    ? "disabledDefaultWideButton"
                    : "disabledWideButton"
                  : ""
              } selectNone`}
              onClick={() => !disabled && handleDifficultyChange("hard")}
            >
              Hard
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default AdvancedSettings;
