import {
  GENERATOR,
  MAP_SIZE_MAX,
  FEATURE_TIERS,
  PATREON_TIERS,
} from "./constants";

export const getMapSizeMax = (panelState) => {
  switch (panelState.generator) {
    case GENERATOR.DUNGEON:
      return MAP_SIZE_MAX[panelState.visuals.type] || 16;
    case GENERATOR.ROAD:
      return 9;
    case GENERATOR.WILDERNESS:
      return 3;
    default:
      return 16;
  }
};

export const getFeatureSetting = (featureKey, tier) => {
  return FEATURE_TIERS[featureKey] &&
    FEATURE_TIERS[featureKey][tier] !== undefined
    ? FEATURE_TIERS[featureKey][tier]
    : null;
};

export const getFirstAvailableTier = (featureKey) => {
  if (!FEATURE_TIERS[featureKey]) return "Patreon";

  const tierValues = Object.entries(FEATURE_TIERS[featureKey]);
  const firstAvailableTier = tierValues.find(
    ([tier, value]) => value === true
  )[0];

  if (firstAvailableTier === PATREON_TIERS.ARCHITECT)
    return PATREON_TIERS.FOUNDER;

  return firstAvailableTier;
};
